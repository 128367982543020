import pureLogo from '../../../images/Website/clients/pure-icon.svg'
import sidLogo from '../../../images/Website/clients/sidbibby-icon.svg'
import yorwarthLogo from '../../../images/Website/clients/yorwarth-icon.svg'

const reviews = [
    {
        stars: 5, 
        name: "Cheryl Baliey", 
        company: "Pure Ely", 
        logo: pureLogo, 
        text: "Yowrwarths highly recommends Daniel at Volley Digital, he did a fantastic job on our website & great photography. Since the website was launched with has much more activity online that we ever had before."
    },
    {
        stars: 5, 
        name: "Sid Bibby", 
        company: "Sid Bibby Turf & Landscaping", 
        logo: sidLogo, 
        text: "Yowrwarths highly recommends Daniel at Volley Digital, he did a fantastic job on our website & great photography. Since the website was launched with has much more activity online that we ever had before."
    },
    {
        stars: 5, 
        name: "Martin Yorwarth", 
        company: "Yorwarths Fresh Fish", 
        logo: yorwarthLogo, 
        text: "Yowrwarths highly recommends Daniel at Volley Digital, he did a fantastic job on our website & great photography. Since the website was launched with has much more activity online that we ever had before."
    },
]

export default reviews;