import React from 'react';

import generateStars from '../../../utils/stars';

import quote from '../../../images/Website/icons/left-quote-primary.svg';

const Testimonial = ({rating, name, company, icon, text, index}) => {
    let starColour
    if (index%2 == 0)
        starColour = '#d63e99';
    else
        starColour = '#3ec8d6';
    return (
        <figure className="testimonial bg-white-default p-8 mx-6 md:mx-4 relative">
            <div className="flex mb-4">
                {rating && generateStars(rating, starColour)}
            </div>
            <div className="flex flex-wrap md:flex-nowrap mb-4 md:mb-6">
                <img className="h-10 mb-0 mr-4" src={icon.file.url} />
                <figcaption className="">
                    {name && <span className="font-regular text-primary-dark">{name}</span>}
                    {company && <em className="block text-xs text-black-light">{company}</em>}
                </figcaption>
            </div>
            {text && text,text && <p className="font-light text-sm">{text.text}</p>}
            <img className="select-none fill-current absolute w-10 lg:w-12 2xl:w-16 top-8 right-8" alt="Quotation Mark" src={quote} />
        </figure>
    );
}

export default Testimonial;